<!-- eslint-disable vue/valid-v-bind -->
<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Sociétés"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>

            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-md-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-md-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="demandes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${statusSocieteVariant(data.item.status)}`"
              >
                {{ data.item.status }}

              </b-badge>
            </div>
          </template>
          <template #cell(firstname)="data">
            {{ data.item.firstname }} {{ data.item.lastname }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
          
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Accepter la demande'"
              variant="gradient-success"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="accepterDemander(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Générer contrat'"
              variant="gradient-warning"
              size="sm"
              :disabled="data.item.status !=='Soumis'"
              class="btn-icon rounded-circle ml-1"
              :to="{ name:'espace-coproprietaire.generer-contrat', params: { id: data.item.id } }"
            >
              <feather-icon icon="AlignCenterIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="demandes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup,
  BInputGroupAppend, BButton, BCardBody, BFormInput, BSpinner, VBModal,

} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useLogements from '@/composables/logementService'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    BBadge,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

   
    const {
      getDemandeLogementsByCoproprietaireId, demandes, accepterDemandeLogementByCoproprietaire, loader,
    } = useLogements()

    const user = JSON.parse(store.state.authStore.user)

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'firstname', label: 'Nom du demandeur', sortable: true },
      { key: 'email', label: 'Email ', sortable: true },
      { key: 'phone', label: 'Téléphone', sortable: true },
      { key: 'created_at', label: 'Date demande', sortable: true },
      { key: 'status', label: 'Status', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      manager_id: null,
      company_name: '',
      description: '',
      categorie_societe_id: null,
      email: '',
      phone: '',
      trade_register: '',
      ifu: '',
      headquarter: '',
      telephonefull: '',
    })
   

    // Variable ref de validation du modal
    // Variable ref du modal
    const myModal = ref('')
    const managerForm = reactive({
      societe_id: null,
      manager_id: null,
    })
    // Fonction du création ou de modification si form.id n'est pas null
  
   


    const telephonefull = ref('')
    // Reset Form
    const resetForm = () => {
      form.id = null
      form.manager_id = null
      managerForm.manager_id = null
      form.company_name = ''
      form.description = ''
      form.categorie_societe_id = ''
      form.email = ''
      form.phone = ''
      form.trade_register = ''
      form.ifu = ''
      form.headquarter = ''
      form.telephonefull = ''
      user.value = []
    }

  
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), () => {
      form.phone = ''
      form.phone = telephonefull.value.formattedNumber
      console.clear()
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }


    const accepterDemander = async id => {
      Swal.fire({
        title: 'Validation',
        text: 'Etes-vous sûr de vouloir accepter ce demandeur ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Accepter',
      }).then(async result => {
        if (result.isConfirmed) {
          await accepterDemandeLogementByCoproprietaire(id)
          await getDemandeLogementsByCoproprietaireId(user.id)
          Swal.fire({

            title: 'Accepté!',
            text: 'Demandeur accepté avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    // Récupérer un enregistrement
    const getItem = async item => {
      form.id = item.id
      managerForm.societe_id = item.id
      form.phone = item.phone
      form.telephonefull = item.phone
      form.manager_id = item.manager_id
      form.company_name = item.company_name
      form.description = item.description
      form.categorie_societe_id = +item.categorie_societe_id
      form.email = item.email
      form.trade_register = item.trade_register
      form.ifu = item.ifu
      form.headquarter = item.headquarter
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const statusSocieteVariant = status => {
      if (status === 'Soumis') return 'primary'
      if (status === 'Rejeté') return 'danger'
      return 'success'
    }

    onMounted(async () => {
      await getDemandeLogementsByCoproprietaireId(user.id)

      // Set the initial number of items
      totalRows.value = demandes.value.length
    })

    return {
      user,
      accepterDemander,
      sortOptions,
      statusSocieteVariant,
      getItem,
      loader,
      pageOptions,
      perPage,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      telephonefull,
      filterOn,
      fields,
      myModal,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      email,
      avatarText,


      demandes,
    }
  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
