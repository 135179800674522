/* eslint-disable global-require */
import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import { paginateArray } from '@/helpers/utils'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'

export default function useLogements() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const demandes = ref([])
  const total = ref(null)
  const loader = ref(false)

  const errors = ref('')

 
  const getDemandeLogementsByCoproprietaireId = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-liste-demandes-logement-by-proprietaire/${id}`)
      if (response.data.success === true) {
        demandes.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const accepterDemandeLogementByCoproprietaire = async id => {
    try {
      errors.value = ''
      const response = await axiosClient.patch(`/publish-demande-logement/${id}`)
      if (response.data.success === true) {
        if (response.data.message === 'Demandeur accepté avec succès') {
          successToast('Rejet de demandeur', response.data.message)
        } else {
          successToast('Acceptation de demandeur', response.data.message)
        }
        loader.value = false
      }
    } catch (error) {
      loader.value = false
      errors.value = ''
      errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }

  return {
    getDemandeLogementsByCoproprietaireId,
    accepterDemandeLogementByCoproprietaire,
    demandes,
    total,
    errors,
    loader,
   

  }
}
